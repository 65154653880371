import React from 'react';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Tab from "@material-ui/core/Tab";
import './Home.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Image1 from '../../Images/Image1.jpg'
import ClickShowImage from '../ClickShowImage';
import Gallery from '../Gallery';
import CarouselComponent from '../CarouselComponent/CarouselComponent';
import Image6 from '../../Images/Image6.jpg';
import Image7 from '../../Images/Image7.jpg';
import Image8 from '../../Images/Image8.jpg';
import Image5 from '../../Images/Image5.webp';
import oneplus2 from '../../Images/oneplus2.png';
import oneplus3 from '../../Images/oneplus3.png';
import ParallaxEffect from '../Parallax/Parallax';
import FadeScrollComponent from '../FadeScrollComponent/FadeScrollComponent';
import Footer from '../Footer/Footer';

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    tab: {
        fontSize: '13px',
    },
});

class Home extends React.Component {

    state = {
        navbarContent: ['Overview', 'Specs'], 
        showTransitionBanner: false,
        images:[Image6,Image7,Image8, Image5, oneplus2, oneplus3,Image1]
    }

    componentDidMount(){
    setTimeout(() => {
        this.setState({ showTransitionBanner: true })
    },2000);
}

render(){
    AOS.init({ duration: 2000 });
    const { classes } = this.props;
    return (
        <div>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root} >
                    <AppBar color="" >
                        <Toolbar >
                            <Typography variant="h6" className={classes.title} >
                                OnePlus Nord
                            </Typography>
                            {
                                this.state.navbarContent.map((ele) => {
                                    return (
                                        <Tab label={ele} className={classes.tab} />
                                    )
                                })
                            }
                            <Button variant="contained" size="small" style={{ backgroundColor: '#00B2D2', color: 'white' }} >
                                Buy Now
                            </Button>
                        </Toolbar>
                    </AppBar>
                </Grid>
                <Grid item container style={{ backgroundColor: 'black', color: "white" }}>
                   {this.state.showTransitionBanner?
                   <>
                    <Grid item xs={6} sm={6} md={6} lg={6} data-Aos="fade-up" style={{ backgroundColor: "white", color: 'black' }} >
                        <div style={{ marginLeft: '12%', marginTop: '19%' }} >
                            <div style={{ fontSize: '130px' }}>OnePlus Nord</div>
                            <div style={{ fontSize: '34px' }}>
                                Pretty much everything
                                <br />
                                you could ask for.
                                <br />
                                From ₹24,999
                                </div>
                            <div style={{ marginTop: '5%' }}>
                                <b>No.1 Selling Smartphone</b>
                                <br />
                                in the Upper Mid-Tier (INR 20K-30K) smartphone category*
                                </div>
                            <br />
                                *Counterpoint India Monthly Model Tracker September 2020
                            </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} data-Aos="slide-right" data-aos-duration="2000" style={{ backgroundColor: "white", color: 'black' }}>
                        <div style={{ marginTop: '8%' }}>
                            <img src={Image1} height="100%" width="80%" />
                        </div>
                    </Grid>
                    </>:
                    <Grid item xs={6} sm={6} md={6} lg={6} data-Aos="fade-up"  data-aos-duration="5000" style={{ backgroundColor: 'black', color: "white" }}>
                           <div style={{ marginLeft: '12%', marginTop: '19%' }} >
                            <div style={{ fontSize: '130px' }}>OnePlus Nord</div>
                            <div style={{ fontSize: '34px' }}>
                                Pretty much everything
                                <br />
                                you could ask for.
                                <br />
                                From ₹24,999
                                </div>
                            <div style={{ marginTop: '5%' }}>
                                <b>No.1 Selling Smartphone</b>
                                <br />
                                in the Upper Mid-Tier (INR 20K-30K) smartphone category*
                                </div>
                            <br />
                                *Counterpoint India Monthly Model Tracker September 2020
                            </div>
                    </Grid>
                }
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root} >
                <ClickShowImage />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root} >
                {/* <ParallaxPart /> */}
                <ParallaxEffect/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root}>
                    <Gallery />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root} style={{backgroundColor:'black'}}>
                     <CarouselComponent show={4}>
                    {
                        this.state.images.map(ele=>{
                            return(
                                <div>
                                    <img src={ele} style ={{
                                        float: 'left',
                                        width:  '300px',
                                        height: '400px',
                                        objectFit: 'cover'
                                    }}/> 
                                </div>
                            )
                        })
                    }
                </CarouselComponent>
                </Grid> 
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root} style={{backgroundColor:'black'}}>
                    <FadeScrollComponent/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root}>
                    <Footer />
                </Grid>
            </Grid>
        </div>

    )
}
}

export default withStyles(useStyles)(Home);