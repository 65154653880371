import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Image6 from '../../Images/Image6.jpg';
import Image7 from '../../Images/Image7.jpg';
import Image8 from '../../Images/Image8.jpg';
import CarouselComponent from './CarouselComponent';

class Carousal extends React.Component {

    state = {
        images: [Image6, Image7, Image8]
    }

    render() {
        return (
            <CarouselComponent show={3}>
                {
                    this.state.images.map(ele => {
                        return (
                            <div>
                                <img src={ele} style={{
                                    float: 'left',
                                    width: '400px',
                                    height: '400px',
                                    objectFit: 'cover'
                                }} />
                            </div>
                        )
                    })
                }
            </CarouselComponent>
        )
    }
}

export default Carousal;
