import React from 'react';
import {Grid, TextField,Button, Typography,Divider} from '@material-ui/core'
import {withStyles} from '@material-ui/core/Styles';

const useStyles= theme =>({
  root: {
    '& .MuiTextField-root':{
      margin:theme.spacing(1)
    },
    width:'100%',
  },
  textField :{
    width:"95%",
    marginLeft:'10px',
    marginRight:'10px',
  },
  resize:{
    fontSize:'15px',
    height:'15px',
    marginLeft:'10px',
    marginRight:'10px',
    width:'100%'
  }
})

class Footer extends React.Component{
  render(){
    const {classes} = this.props;
    return(
      <Grid container>
        <Grid item container xs={6} sm={6} md={6} lg={6} style={{marginTop:'20px'}}>
          <Grid item xs={4} sm={4} md={4} lg={4}>
          <Typography style={{fontSize:"15px", fontWeight:"bold", marginLeft: "27px"}}>Phones</Typography>
          <ul>
            <li><a href>OnePlus 9 Pro 5G</a></li>
            <br/>
            <li><a href>OnePlus 9 5G</a></li>
            <br/>
            <li><a href>OnePlus 9 5G</a></li>
          </ul>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
          <Typography style={{fontSize:"15px", fontWeight:"bold", marginLeft: "27px"}}>Accessories</Typography>
          <ul>
            <li><a href>Wearables</a></li>
            <br/>
            <li><a href>Cases & Protection</a></li>
            <br/>
            <li><a href>Power & Cables</a></li>
          </ul>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
          <Typography style={{fontSize:"15px", fontWeight:"bold",marginLeft: "27px"}}>Services</Typography>
          <ul>
            <li><a href>Education Benefits</a></li>
            <br/>
            <li><a href>Buyback Program</a></li>
            <br/>
            <li><a href>Referral Program</a></li>
          </ul>
          </Grid>
        </Grid>
        <Grid item container xs={6} sm={6} md={6} lg={6} style={{marginTop:'30px'}}>
            <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.root}>
                <TextField
                  fullWidth="true"
                  required
                  variant="outlined"
                  label="Name"
                  InputProps={{
                    classes : {
                      input :classes.resize
                    }
                  }}
                  InputLabelProps = {{
                    shrink : true
                  }}
                  placeholder="Name"
                  >
                </TextField>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.root}>
                  <TextField
                    style={{marginLeft:'10px',marginRight:'10px '}}
                    required
                    fullWidth="true"
                    variant="outlined"
                    label="Email"
                    InputProps={{
                      classes : {
                        input :classes.resize
                      }
                    }}
                    InputLabelProps = {{
                      shrink : true
                    }}
                    placeholder="Email"
                    >
                      
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root}>
                  <TextField
                    required
                    fullWidth="true"
                    variant="outlined"
                    label="Message"
                    InputProps={{
                      classes : {
                        input :classes.resize
                      }
                    }}
                    InputLabelProps = {{
                      shrink : true
                    }}
                    placeholder="Message"
                    multiline
                    rowsMax={4}
                    >
                      
                  </TextField>
                </Grid>  
            </Grid>
            
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button variant="contained" color="primary" style={{width:'100%',marginBottom:'20px'}}>
              Submit
            </Button>
            </Grid>
            <Divider/>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider></Divider><Divider />
        </Grid>
                   
            <Grid item container xs={12} sm={12} md={12} lg={12} style={{paddingBottom:'23px'}}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                Credit / Debit Card  NetBanking  EMI  Wallet
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                1800 102 8411 (Toll Free) 9:00 am - 9:00 pm, Mon to Sun
              </Grid>    
            </Grid>
            <Divider />
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Divider></Divider><Divider />
            </Grid>
            <Grid item container xs={12} sm={12} md={12} lg={12} style={{marginTop:'2%'}}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                Credit / Debit Card  NetBanking  EMI  Wallet
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                1800 102 8411 (Toll Free) 9:00 am - 9:00 pm, Mon to Sun
              </Grid>    
            </Grid>
      </Grid>
    )
  }
}

export default withStyles(useStyles)(Footer);
