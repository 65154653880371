import React,{useState} from 'react';

import './style.css';

function FadeInSection(props) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

function FadeScrollComponent() {
    const [color, setColor] = useState(['black']);
    const listenScrollEvent = e => {
        if (window.scrollY < 5350) {
            return setColor(["black"]) ;
          } else  {
            return setColor(["white"]) ;
          }
      }
    React.useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent)
      }, []);
  return (
    <div className="App">
      {color.map(ele => (
        <FadeInSection key={ele}>
          <div className="box" style={{ background: ele , color:(ele==="black"?'white':'black')}}>
            {/* <span>{color}</span> */}
            <div id="header">
          <h1>
              fsefd
          </h1>
       </div>
       <div id="section_1" className="section">
        A phone that’s smarter
        about being bright.
        Nord’s display has 2,048 levels of automatic brightness for smoother brightness  
        transitions and greater eye comfort when you’re doing everything from scrolling 
        through social media to browsing a beautifully designed product page.
       </div>

       <div id="section_2" className="section" style={{marginTop:"10%"}}>
          This is section 2
       </div>
       <div id="section_5" className="section" style={{marginTop:"10%"}}>
          This is section 5
       </div> <div id="section_1" className="section" style={{marginTop:"10%"}}>
          This is section 1
       </div>
          </div>
        </FadeInSection>
      ))}
    </div>
  );
}

export default FadeScrollComponent;

