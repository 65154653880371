import React from "react";
import { Parallax, Background } from "react-parallax";
import Image1 from '../../Images/oneplus1.png';
import Image7 from '../../Images/oneplus2.png';
import Image99 from '../../Images/oneplus3.png';

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center",
};
const insideStyles = {
  background: "white",
  padding: 20,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)"
};
const image1 = Image1 ;
const image2 = Image99 ;
const image3 = Image7 ; 

const ParallaxEffect = () => (
  <div style={styles}>
    <Parallax strength={500}>
        <Background className="custom-bg">
            <img src={image1} alt="fill murray" 
                style ={{
                    width:  '300px',
                    height: '100%',
                    objectFit: 'cover'
                }}/>
        </Background>
      <div style={{ height: "90vh"  }}>
      </div>
    </Parallax>

    <Parallax blur={{ min: -1, max: 3 }}>
        <Background className="custom-bg">
            <img src={image3} alt="fill murray" 
            style ={{
                width:  '300px',
                height: '100%',
                objectFit: 'cover'
            }}/>
        </Background>
      <div style={{ height: "70vh"  }}>
      </div>
    </Parallax>
    
    <Parallax  strength={-100}>
        <Background className="custom-bg">
            <img src={image2} alt="fill murray" 
            style ={{
                width:  '300px',
                height: '100%',
                objectFit: 'cover'
            }}/>
        </Background>
      <div style={{ height: "100vh"  }}>
      </div>
    </Parallax>
    {/* <Parallax bgImage={image3} blur={{ min: -1, max: 3 }}>
      <div style={{ height: "100vh" }}>
        {/* <div style={insideStyles}>Dynamic Blur</div> */}
      {/* </div>
    </Parallax> */}
    {/* <Parallax bgImage={image2} strength={-100}>
      <div style={{ height: "100vh"  }}>
      </div>
    </Parallax> */} 
  </div>
);
export default ParallaxEffect