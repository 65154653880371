import React from 'react';
import { Grid } from '@material-ui/core';
import Image1 from '../Images/Image1.jpg';
import Image4 from '../Images/Image4.webp';
import Image5 from '../Images/Image5.webp';
import Image6 from '../Images/Image6.jpg';
import Image7 from '../Images/Image7.jpg';
import Image8 from '../Images/Image8.jpg';
import { withStyles } from '@material-ui/core/styles';
import Aos from 'aos';
import Carousel from './CarouselComponent/Carousel';

const useStyles = (theme) => ({
    mainGrid: {
        backgroundColor: 'black',
        padding: '2%'
    }
});

let obj = {

}
class MovableImages extends React.Component {
    state = {
        galleryArr: [
            {
                image: Image1, effect: "slide-left",
                style: {
                    transform: 'rotate(39deg)',
                    fontFamily: 'cursive',
                    fontSize: '18px',
                    color: 'white'
                },
                text: "The best moment of the day is golden hours"
            },

            {
                image: Image4, effect: "fade-right",
                style: {
                    transform: 'rotate(270deg)',
                    fontFamily: 'cursive',
                    fontSize: '18px',
                    color: 'white'
                },
                text: "I am inspired by the everyday,  by being able to find something extrordinary and beautiful"
            },

            {
                image: Image5, effect: "slide-up",
                style: {
                    transform: 'rotate(310deg)',
                    fontFamily: 'cursive',
                    fontSize: '15px',
                    color: 'white',
                    fontStyle: 'italic'
                },
            },

            {
                image: Image6, effect: "slide-right",
                style: {
                    transform: 'rotate(90deg)',
                    fontFamily: 'cursive',
                    fontSize: '18px',
                    color: 'white'
                },
                text: "The best moment of the day is golden hours"
            },

            {
                image: Image7, effect: "slide-left",
                style: {
                    transform: 'rotate(0deg)',
                    fontFamily: 'cursive',
                    fontSize: '15px',
                    color: 'white'
                },
                text: "Nord comes with the same 48 MP primary sensor as the OnePlus 8, complete with optical image stabilization (OIS) to eliminate blur and shakiness from every photo."
            },

            {
                image: Image8, effect: "flip-left",
                style: {
                    transform: 'rotate(350deg)',
                    fontFamily: 'cursive',
                    fontSize: '18px',
                    color: 'white',
                    fontStyle: 'italic|bold',

                },
                text: "IN STOCK HOME puy choose to consent to our use of these technologies, or manage your own prefe"
            },
        ]
    }
    render() {
        const { classes } = this.props;
        Aos.init({ duration: 2000 })
        return (
            <Grid container spacing={3} className={classes.mainGrid} >
                {this.state.galleryArr.map(ele => {
                    return (
                        <>
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                <img src={ele.image} height="300" data-aos={ele.effect} />
                                <div style={ele.style}>{ele.text}</div>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                            </Grid>
                        </>
                    )
                })}
                <Grid item xs={2} sm={2} md={2} lg={2} style={{ marginTop: '8%' }}>

                </Grid>
            </Grid>
        )
    }
}

export default withStyles(useStyles)(MovableImages);