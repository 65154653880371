import React from 'react';
import {Grid,List,ListItem} from '@material-ui/core';

import Image4 from '../Images/Image4.webp';
import Image5 from '../Images/Image5.webp';
import Image6 from '../Images/Image6.jpg';
import Image7 from '../Images/Image7.jpg';
import Image8 from '../Images/Image8.jpg';
import Image9 from '../Images/Image9.jpg';
import Image10 from '../Images/Image10.jpg';
import Image11 from '../Images/Image11.jpeg';

class ClickShowImage extends React.Component{

    state={
        arr : [
            {name:'Flagship Camera',imgsrc:Image4},
            {name:'Nightscape',imgsrc:Image5},
            {name:'Dual Selfie camera',imgsrc:Image6},
            {name:'Oxygen OS',imgsrc:Image7},
            {name:'90 Hz AMOLED display',imgsrc:Image8},
            {name:'Snapdragon 765G',imgsrc:Image9},
            {name:'5G-ready',imgsrc:Image10},
            {name:'Warp charge 30T',imgsrc:Image11}
        ],
        colorId: 0
    }

    onItemHover = (index)=>{
        this.setState({colorId:index})
    }

    render(){
        return(
                <Grid container style={{marginTop:'7%'}}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                            <List>
                                {
                                this.state.arr.map((ele,index)=>{
                                    return(
                                        <ListItem 
                                            key={index}
                                            style={{color:this.state.colorId===index?'black':'gray',fontSize:'36px',marginLeft:'10%'}}
                                            onMouseOver={()=> this.onItemHover(index)}
                                        >
                                            {ele.name}
                                        </ListItem>
                                    )
                                })
                            }
                            </List>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <img src={this.state.arr[this.state.colorId].imgsrc} width="80%" height="90%" />
                    </Grid>
                </Grid>
        )
    }
}

export default ClickShowImage;