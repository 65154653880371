import { Button, IconButton, Typography  } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { useEffect, useState } from 'react'
import './CarouselComponent.css';

const CarouselComponent = (props) => {
    const {children, show} = props

    const [currentIndex, setCurrentIndex] = useState(0)
    const [length, setLength] = useState(children.length)

    const [touchPosition, setTouchPosition] = useState(null)

    // Set the length to match current children from props
    useEffect(() => {
        setLength(children.length)
    }, [children])

    const next = () => {
        if (currentIndex < (length - 1)) {
            setCurrentIndex(prevState => prevState + 1)
        }
    }

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
        }
    }

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }

    const handleTouchMove = (e) => {
        const touchDown = touchPosition

        if(touchDown === null) {
            return
        }

        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch

        if (diff > 5) {
            next()
        }

        if (diff < -5) {
            prev()
        }

        setTouchPosition(null)
    }

    return (
        <div id = "mainRoot">
        <div id = "button">
            <Typography style={{color:'white'}}>
            Nord in the 
            hands of the 
            OnePlus staff.
            (That think they’re pros).
            Download every photo in 
            its full resolution here.

            </Typography>
            <IconButton  component="span" style={{ color: 'white' }} onClick={prev}>
            <ArrowBackIcon />
            </IconButton>
            <span style={{ color: 'white' }}>{currentIndex+1}/{length}</span>
            <IconButton  component="span" style={{ color: 'white' }} onClick={next}>
            <ArrowForwardIcon />
            </IconButton>
        </div>
        <div className="carousel-container" id="container">
            <div className="carousel-wrapper">
                <div
                    className="carousel-content-wrapper"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                >
                    <div
                        className={`carousel-content show-${show}`}
                        style={{ transform: `translateX(-${currentIndex * (100 / show)}%)` }}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default CarouselComponent